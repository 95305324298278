import { BrowserRouter, Route, Routes } from "react-router-dom";

import { MarketingPage } from "./marketing/MarketingPage";
import { CustomAuth0Provider } from "./providers";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { useEffect } from "react";

export const App = () => {
  return (
    <BrowserRouter>
      <CustomAuth0Provider>
        <Routes>
          <Route path="/*" element={<MarketingPage />} />
          <Route path="/app/*" element={<AuthenticatedRoute />} />
        </Routes>
      </CustomAuth0Provider>
    </BrowserRouter>
  );
};
