import { useMemo } from "react";

import { ConnectExternalResource } from "../../components";
import { useAppContext } from "../../providers";
import { GetResourcesQuery } from "../../__generatedGQL__/graphql";

export const GithubAuth = ({ resource }: { resource: GetResourcesQuery["resources"][0] }) => {
  const { user, selectedClientId, showOldTuskUI } = useAppContext();
  const githubEnabled = !!resource;

  const githubAuthUrl = useMemo(() => {
    const state = {
      clientId: selectedClientId,
      userId: user.id,
    };
    return `https://github.com/apps/${
      process.env.GITHUB_APP_NAME
    }/installations/new?state=${encodeURIComponent(JSON.stringify(state))}`;
  }, [selectedClientId, user.id]);

  return (
    <ConnectExternalResource
      authed={githubEnabled}
      onConnect={() => window.location.assign(githubAuthUrl)}
      resourceName="GitHub"
      resourceLogo="https://asset.brandfetch.io/idZAyF9rlg/idw3q8jby5.png"
      resourceDescription={
        showOldTuskUI ? "Sync repos and create pull requests" : "Enable automated tests on repos"
      }
    />
  );
};
