import { useState, useMemo } from "react";
import { PencilSquareIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Button, RepoLimitAlert, CustomInstructionsModal, CopyText } from "../components";
import { GetReposQuery, RepoUpdateInput } from "../__generatedGQL__/graphql";
import { filter, includes, map, isEqual, size } from "lodash";
import { useAppContext } from "../providers";
import { getMaxSyncedRepos, getPlanName, isPaidPlan } from "../containers";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

interface IProps {
  organizationName: string;
  repos: GetReposQuery["repos"];
  updateEnabledRepos: (repoIds: number[]) => Promise<void>;
  syncRepos: () => Promise<void>;
  updateRepo: (id: number, input: RepoUpdateInput) => Promise<void>;
}

type Repo = GetReposQuery["repos"][0];

export const GithubForm = ({
  organizationName,
  repos,
  updateEnabledRepos,
  syncRepos,
  updateRepo,
}: IProps) => {
  const { selectedClient, showOldTuskUI } = useAppContext();
  const initialEnabledRepos = map(
    filter(repos, (repo) => repo.enabled),
    "id",
  );
  const [enabledRepos, setEnabledRepos] = useState<number[]>(initialEnabledRepos);
  const [isSavingRepos, setIsSavingRepos] = useState(false);
  const [isCustomInstructionsModalOpen, setIsCustomInstructionsModalOpen] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState<Repo | null>(null);

  const handleRepoEnabledChange = (id: number) => {
    const updatedEnabledRepos = [...enabledRepos];
    if (updatedEnabledRepos.includes(id)) {
      updatedEnabledRepos.splice(updatedEnabledRepos.indexOf(id), 1);
    } else {
      updatedEnabledRepos.push(id);
    }
    setEnabledRepos(updatedEnabledRepos);
  };

  const onSave = async () => {
    setIsSavingRepos(true);
    await updateEnabledRepos(enabledRepos);
    setIsSavingRepos(false);
  };
  const [isSyncingRepos, setIsSyncingRepos] = useState(false);
  const onSyncRepos = async () => {
    setIsSyncingRepos(true);
    await syncRepos();
    setIsSyncingRepos(false);
  };

  const [maxRepos, editingDisabled] = useMemo(() => {
    const maxRepos = getMaxSyncedRepos(selectedClient?.subscriptionPlan);
    return [maxRepos, enabledRepos.length >= maxRepos];
  }, [enabledRepos]);

  return (
    <>
      {(enabledRepos.length + 1 >= maxRepos || !isPaidPlan(selectedClient?.subscriptionPlan)) && (
        <div className="mb-8">
          <RepoLimitAlert
            maxRepos={maxRepos}
            plan={getPlanName(selectedClient?.subscriptionPlan)}
          />
        </div>
      )}
      <>
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-lg font-semibold leading-7 text-gray-900">GitHub Integration</h2>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              {showOldTuskUI
                ? "Select which repositories you'd like to sync with Tusk."
                : "Select which repositories you'd like to enable for Tusk Tester."}
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="organization"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Organization
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <span className="block flex-1 border-0 select-none items-center py-1.5 pl-3 text-gray-500 sm:text-sm sm:leading-6">
                      {organizationName}
                    </span>
                    {/* {/* <input
                        type="text"
                        name="organization"
                        id="organization"
                        autoComplete="organization"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />  */}
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="repositories"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Repositories
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  {repos.map((repo, index) => (
                    <div key={repo.id} className="flex flex-col">
                      <div className="flex items-center text-sm">
                        <input
                          type="checkbox"
                          id={`repository-${repo.id}`}
                          disabled={!includes(enabledRepos, repo.id) && editingDisabled}
                          checked={includes(enabledRepos, repo.id)}
                          onChange={() => handleRepoEnabledChange(repo.id)}
                          className="mr-2 text-purple-600 focus:ring-purple-500 rounded disabled:cursor-not-allowed disabled:opacity-50"
                        />
                        <label htmlFor={`repository-${repo.id}`} className="text-gray-900">
                          {repo.name}
                        </label>
                        {repo.enabled && (
                          <button
                            onClick={() => {
                              setSelectedRepo(repo);
                              setIsCustomInstructionsModalOpen(true);
                            }}
                            className="ml-2 text-gray-400 hover:text-gray-500"
                            aria-label={`Edit custom instructions for ${repo.name}`}
                          >
                            <PencilSquareIcon className="h-4 w-4" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                      {showOldTuskUI && (
                        <>
                          {size(repo.config?.blockedDirs) > 0 && (
                            <div className="flex flex-wrap mt-1">
                              <div className="mr-2 ml-6">
                                <span className="inline-flex items-center text-sm">
                                  Blocked directories:
                                </span>
                              </div>
                              {repo.config?.blockedDirs.map((dir, index) => (
                                <span
                                  key={index}
                                  className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20 mr-2"
                                >
                                  {dir}
                                </span>
                              ))}
                            </div>
                          )}
                          {repo.defaultBranch && repo.enabled && (
                            <div className="flex flex-wrap mt-1">
                              <div className="mr-2 ml-6">
                                <span className="inline-flex items-center text-sm">
                                  Default branch:
                                </span>
                              </div>
                              <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20 mr-2">
                                {repo.defaultBranch}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      {!showOldTuskUI && (
                        <>
                          {size(repo.testingSandboxConfigs) > 0 && (
                            <div className="flex flex-wrap mt-1 mb-1">
                              <div className="mr-2 ml-6">
                                <span className="inline-flex items-center text-sm">
                                  Test execution environments:
                                </span>
                              </div>
                              {map(repo.testingSandboxConfigs, (config) => (
                                <div
                                  key={config.id}
                                  className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20 mr-2"
                                >
                                  {config.appDir ? config.appDir : ""}
                                  {config.appDir && config.testFramework ? " - " : ""}
                                  {config.testFramework ? config.testFramework : ""}
                                </div>
                              ))}
                            </div>
                          )}
                          {repo.enabled &&
                            (!repo.testingConfig?.testCheckEnabled ||
                              size(repo.testingSandboxConfigs) === 0) && (
                              <div className="flex flex-wrap ml-6 mt-1 mb-1 items-center">
                                <ExclamationTriangleIcon
                                  className="h-5 w-5 text-yellow-400"
                                  aria-hidden="true"
                                />
                                <span className="inline-flex items-center px-2 py-1 text-sm font-normal text-gray-600">
                                  Additional setup required, please contact&nbsp;
                                  <CopyText text="founders@usetusk.ai" type="email" />.
                                </span>
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  ))}
                  <div className="mt-6">
                    <Button
                      size="xs"
                      variant="secondary"
                      onClick={onSyncRepos}
                      loading={isSyncingRepos}
                      disabled={isSyncingRepos}
                      tooltipText="If you've added new repositories to your organization, this will update the list of repositories available to sync with Tusk."
                    >
                      Update repos
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 mr-7 flex items-center justify-end gap-x-6">
          <Button
            size="md"
            variant="secondary"
            disabled={isEqual(initialEnabledRepos, enabledRepos)}
            onClick={() => setEnabledRepos(initialEnabledRepos)}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </Button>
          <Button
            size="md"
            loading={isSavingRepos}
            onClick={onSave}
            disabled={isEqual(initialEnabledRepos, enabledRepos) || enabledRepos.length > maxRepos}
          >
            Save changes
          </Button>
        </div>
      </>
      <CustomInstructionsModal
        key={selectedRepo?.id}
        isOpen={isCustomInstructionsModalOpen}
        onClose={() => setIsCustomInstructionsModalOpen(false)}
        repoName={selectedRepo?.name}
        currentInstructions={selectedRepo?.context}
        onUpdateInstructions={async (instructions) => {
          await updateRepo(selectedRepo?.id, { context: instructions });
          // Set selected repo with updated instructions
          setSelectedRepo({ ...selectedRepo, context: instructions });
        }}
      />
    </>
  );
};
